//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import search from "@/mixins/search";
import navigationContent from "@/mixins/navigationContent";
import { directive as onClickaway } from "vue-clickaway2";

export default {
  created() {
    if (process.browser)
      window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.$nuxt.$on("activateSearch", () => {
      this.handleShowSearch();
    });
  },
  destroyed() {
    if (process.browser)
      window.removeEventListener("scroll", this.handleScroll);
  },
  directives: {
    onClickaway: onClickaway,
  },
  mixins: [search, navigationContent],
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route() {
      this.businessMenu = false;
      this.personalMenu = false;
      this.landlordsMenu = false;
    },
  },
  methods: {
    handleScroll() {
      if (!window) return;
      if (window.scrollY > 100) {
        this.makeNavSticky = true;
      } else this.makeNavSticky = false;
    },
    hideBusinessMenu() {
      if (this.businessMenu === true) this.businessMenu = false;
    },
    hidePersonalMenu() {
      if (this.personalMenu === true) this.personalMenu = false;
    },
    hideLandlordsMenu() {
      if (this.landlordsMenu === true) this.landlordsMenu = false;
    },
    hideHelpMenu() {
      if (this.helpMenu === true) this.helpMenu = false;
    },
  },
  computed: {
    helpOptions() {
      return [
        {
          label: "Help Guides",
          value: "articles",
          link: "/guides",
        },
        {
          label: "Billing FAQs",
          value: "billing faqs",
          link: "/guides/categories/billing-faqs",
        },
      ];
    },
    profile() {
      return this.$store
        .$db()
        .model("profile")
        .query()
        .first();
    },
    isLoggedIn() {
      if (this.profile && this.profile.registered) return true;
      else return false;
    },
  },
  data() {
    return {
      makeNavSticky: false,
      query: null,
      businessMenu: false,
      personalMenu: false,
      landlordsMenu: false,
      helpMenu: false,
      formationList: [
        {
          name: "Start an LLC",
          url: "/business-formation/llc",
        },
        {
          name: "Form a C-CORP",
          url: "/business-formation/c-corporation",
        },
        {
          name: "File an S-CORP",
          url: "/business-formation/s-corporation",
        },
        {
          name: "Register a Nonprofit",
          url: "/business-formation/nonprofit",
        },
      ],
      bizServicesList: [
        {
          name: "Automated Compliance Management",
          url: "/compliance-guard",
        },
        {
          name: "BOI Report",
          url:
            "/categories/business-services/beneficial-ownership-information-report",
        },
        {
          name: "Registered Agent",
          url: "/registered-agent",
        },
        {
          name: "Certificate of Good Standing",
          url: "/categories/business-services/certificate-of-good-standing",
        },
      ],
      bizDocsList: [
        {
          name: "LLC Operating Agreement",
          url: "/categories/business-formation/llc-operating-agreement",
        },
        {
          name: "Articles of Organization",
          url: "/categories/business-formation/articles-of-organization",
        },
        {
          name: "Non Disclosure Agreement",
          url: "/categories/human-resources/non-disclosure-agreement",
        },
        {
          name: "Contractor Agreement",
          url: "/categories/human-resources/Independent-Contractor-Agreement",
        },
      ],
      leasePrepList: [
        {
          name: "Residential Rental Agreement",
          url: "/categories/landlord-and-tenant/residential-rental-agreement",
        },
        {
          name: "Lease Agreement",
          url: "/categories/landlord-and-tenant/lease-agreement",
        },
        {
          name: "Month to Month Rental Agreement",
          url: "/categories/landlord-and-tenant/month-to-month-leases",
        },
        {
          name: "Sublease Agreement",
          url: "/categories/landlord-and-tenant/residential-sublease-agreement",
        },
        {
          name: "Commercial Lease Agreement",
          url: "/categories/commercial-property/commercial-lease-agreement",
        },
      ],
      leaseManagementList: [
        {
          name: "Lease Amendment",
          url: "/categories/landlord-and-tenant/residential-lease-amendment",
        },
        {
          name: "Late Rent Notice",
          url: "/categories/landlord-and-tenant/late-rent-notice",
        },
        {
          name: "Rent Receipt",
          url: "/categories/landlord-and-tenant/rent-receipt",
        },
        {
          name: "Notice of Rent Increase",
          url: "/categories/landlord-and-tenant/notice-of-rent-increase",
        },
        {
          name: "Pet Addendum",
          url: "/categories/landlord-and-tenant/pet-addendum",
        },
        {
          name: "Commercial Lease Amendment",
          url: "/categories/commercial-property/commercial-lease-amendment",
        },
      ],
      leaseTerminationList: [
        {
          name: "Eviction Notice",
          url: "/categories/landlord-and-tenant/eviction-notice",
        },
        {
          name: "Notice to Vacate",
          url: "/categories/landlord-and-tenant/notice-to-vacate",
        },
        {
          name: "Lease Termination Agreement",
          url: "/categories/landlord-and-tenant/lease-termination",
        },
      ],
      personalOtherList: [
        {
          name: "General Affidavit",
          url: "/categories/personal-affairs/general-affidavit",
        },
        {
          name: "Bankruptcy Worksheet",
          url: "/categories/personal-affairs/bankruptcy-worksheet",
        },
        {
          name: "Cease and Desist",
          url: "/categories/personal-affairs/cease-and-desist-letter",
        },
        {
          name: "Prenuptial Agreement",
          url: "/categories/marriage-and-divorce/prenuptial-agreement",
        },
        {
          name: "Postnuptial Agreement",
          url: "/categories/marriage-and-divorce/postnuptial-agreement",
        },
        {
          name: "Affidavit of Mariage",
          url: "/categories/marriage-and-divorce/affidavit-of-marriage",
        },
        {
          name: "Cover Letter",
          url: "/categories/personal-affairs/cover-letter",
        },
        {
          name: "Mortgage Agreement",
          url: "/categories/property/mortgage-agreement",
        },
      ],
      personalPOAList: [
        {
          name: "General Power of Attorney",
          url: "/categories/estate-planning/power-of-attorney",
        },
        {
          name: "Medical Power of Attorney",
          url: "/categories/healthcare/medical-power-of-attorney",
        },
        {
          name: "Power of Attorney Revocation",
          url: "/categories/personal-affairs/revocation-poa",
        },
      ],
      personalDeedsList: [
        {
          name: "Quit Claim Deed",
          url: "/categories/property/quit-claim-deed",
        },
        {
          name: "General Warranty Deed",
          url: "/categories/property/general-warranty-deed",
        },
        {
          name: "Property Deed",
          url: "/categories/property/property-deed",
        },
        {
          name: "Special Warranty Deed",
          url: "/categories/property/special-warranty-deed",
        },
      ],
    };
  },
};
