import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class UserOrders extends BaseModel {
  static entity = "UserOrders";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      created_at: this.attr(null),
      version_slug: this.attr(null),
      is_v2: this.attr(null),
      name: this.attr(null),
      status: this.attr(null),
      order_statuses: this.attr([]),
      purchased_at: this.attr(null),
      processing_completed_at: this.attr(null),
      product_name: this.attr(''),
      company_id: this.attr(null)
    };
  }

  static async fetchAll() {
    let { data } = await this.axios.get(`/orders`);
    Requests.markAsLoaded('Orders')
      this.create({ data });
  }

  static async dismissOrder(orderID) {
    await this.axios.delete(`/orders/${orderID}.json`);
    await this.fetchAll();
  }
}
