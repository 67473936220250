import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";
import Order from "./Order";

export default class Company extends BaseModel {
  static entity = "Company";

  static fields() {
    return {
      name: this.attr(null),
      address: this.attr(""),
      date: this.attr(null),
      taxClassification: this.attr(null),
      entity_type: this.attr(""),
      business_type: this.attr(null),
      state_formation: this.attr(""),
      logo_url: this.attr(null),
      has_formation: this.attr(false),
      contact: this.attr(null),
      agent: this.attr(null),
      status: this.attr(null),
      formation_date: this.attr(null),
      ein: this.attr(null),
      foreign_entity_of: this.attr(null),
      registered_agent_active: this.attr(false),
      compliance_guard_active: this.attr(false),
      write_protected_attributes:this.attr(null),
      state_company_id: this.attr(null)
    };
  }


  static async fetchCompany(id) {
    let { data } = await this.axios.get(`/companies/${id}`);
    Requests.markAsLoaded('Company' + id) 
    this.create({ data });
    
  }

  static async createCompany(companyData) {
    let { data } = await this.axios.post(`/companies`, {
      name: companyData.name,
      formation_date: companyData.formation_date,
      entity_type: companyData.entity_type,
      state_id: companyData.state_id,
      state_company_id: companyData.state_company_id

    });
    this.create({ data });
    return data;
  }

  static async createFE(companyData, company) {
    let { data } = await this.axios.post(`/companies`, {
      foreign_entity_of: company.id,
      name: company.name,
      state_id: companyData.fe_state_id,
      entity_type: company.entity_type
    });
    this.create({ data });
    return data;
  }

  // by default findBy ID.
  static findByUrl({ id }) {
    return `/companies/${id}`;
  }
  
  static async findBy(params) {
    let response = await this.axios.get(this.findByUrl(params));
    return response.data;
  }

  static async uploadLogo(id, logo) {
    let formData = new FormData();
    formData.append('logo', logo.logo);  
     return this.axios
       .patch(`/companies/${id}`, formData)
       .then(({ data }) => {
          this.create({ data })
      });
  }

  static async updateCompany(id, companyData) {
    let name = companyData.name
    if(companyData.entity_designator){
      name += ' ';
      name += companyData.entity_designator;
    }

    let { data } = await this.axios.patch(`/companies/${id}`, {
      name: companyData.name,
      formation_date: companyData.formation_date,
      entity_type: companyData.entity_type,
      state_id: companyData.state_id,
      state_company_id: companyData.state_company_id
    });
    this.update({ data });
    return data;
  }

}
