import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class UserSavedOrders extends BaseModel {
  static entity = "UserSavedOrders";

  static fields() {
    return {
      companies: this.attr([])
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/orders/saved`);
    Requests.markAsLoaded('UserSavedOrders')
      this.create({ data });
  }
}
