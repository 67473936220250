import { render, staticRenderFns } from "./desktop-menu.vue?vue&type=template&id=ae547416&"
import script from "./desktop-menu.vue?vue&type=script&lang=js&"
export * from "./desktop-menu.vue?vue&type=script&lang=js&"
import style0 from "./desktop-menu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Chevron: require('/usr/src/app/components/account/common-elements/icons/chevron.vue').default,SearchBar: require('/usr/src/app/components/base-header/components/search-bar.vue').default,SearchModal: require('/usr/src/app/components/base-header/components/search-modal.vue').default})
