import { Model } from "@vuex-orm/core";

export default class Requests extends Model {
  static entity = "requests";

  static fields() {
    return {
      id: this.attr(null),
      hasBeenLoaded: this.attr(false),
      error: this.attr(''),
    }
  }

  get isLoaded(){
    if (!this) return false;
    return this.hasBeenLoaded === true;
  }

  static async markAsLoaded(id) {
    this.insert({ data: {id, hasBeenLoaded: true }});
  }

  static async markAsNotLoaded(id) {
    this.delete(id);
  }
  static async markAsErrorOccurred(id, error) {
    this.insert({ data: {id, hasBeenLoaded: true, error}});
  }
}
