import BaseOrder from "./BaseOrder";
import OrderForm from "./OrderForm";
import { stateNameByValue } from "@/src/states";
import { cloneDeep } from "lodash";
import { fsEvent } from "~/services/util";

export default class Order extends BaseOrder {
  static entity = "orders";

  static fields() {
    return {
      id: this.attr(null),
      state: this.attr(null),
      version_slug: this.attr(""),
      type: this.attr(""),
      data: this.attr({}),
      current_page_id: this.attr(-1),
      completed: this.attr(false),
      company_id: this.attr(null),
      send_back_to_review: this.attr(false),
      is_form_loading: this.attr(true),
      is_form_error: this.attr(false),
      is_changing_page: this.attr(false),
      is_auth_modal: this.attr(false),
      is_save_for_later: this.attr(false),
      is_save_for_later_modal: this.attr(false),
      is_checkout: this.attr(false),
      form_rendering_timestamp: this.attr(undefined),
      completed_page_set: this.attr([]),
      is_v2: this.attr(false),
      experiment_id: this.attr(0),
      variant_id: this.attr(0),
      update_company_field: this.attr(''),
      order_statuses: this.attr([]),
      purchased_at: this.attr(null),
      product_name: this.attr(''),
      created_at: this.attr(null),
      entity_type: this.attr(null),
      processing_completed_at: this.attr(null),

    };
  }

  get humanizedType() {
    let humanizedEntityTypes = {
      llc: "LLC",
      s_corp: "S Corp",
      c_corp: "C Corp",
      nonprofit: "Nonprofit",
    };

    return humanizedEntityTypes[this.data.entity_type] || this.data.entity_type;
  }

  get authTitle() {
    return `Your ${this.humanizedType} is ready to be filed`;
  }

  saveForLater(registered) {
    if(registered)
      this.sendSaveForLater()
    else
     this.$update({ is_auth_modal: true, is_save_for_later: true });
  }

  async sendSaveForLater() {
    this.$update({ is_save_for_later: true });
    fsEvent("Order > Save for Later", { version_slug: this.version_slug });
    let response = await this.constructor.axios.patch(
      `${this.constructor.findByUrl({ id: this.id })}/save_for_later`,
      {}
    );
    this.$update({ is_save_for_later_modal: true });
    this.$update({ is_save_for_later: false });

  }

  async sendToDashboard(){
    if (this.company_id)
       window.location = `/account/companies/${this.company_id}`;
    else window.location = "/account";
    return response
  }

  get orderFormParams() {
    return {
      orderId: this.id,
      version_slug: this.version_slug,
      order_type: "order",
    };
  }

  get route() {
    return "/orders/new";
  }

  get eventType() {
    return "Order";
  }

  static async uploadFile(orderId, file ) {
    let formData = new FormData();
    formData.append('order_attachment[file]', file.file, file.fileName);  
     return this.axios
       .post(`/orders/${orderId}/order_attachments`, formData )
       .then(({ data }) => {
        return data
      });
  }

  static async deleteFile(orderId, fileId ) {
     return this.axios
       .delete(`/orders/${orderId}/order_attachments/${fileId}` )
       .then(({ data }) => {
      });
  }

  static async fetchByCompanyId(companyId) {
    this.axios
      .get(`/orders?company_id=${companyId}`)
      .then(async (data) => {
        await this.create(data)
      })
  }

  apiSave(data) {
    let companyData
    if(this.update_company_field) {
      if (!companyData) companyData = {}
      companyData[this.update_company_field] = this.data[this.update_company_field]
    }
    let orderData 

    if(parseInt(this.data.state_id) !== this.state){
    orderData = {
      state: parseInt(this.data.state_id),
      data: data ? data : this.data,
      current_page: this.current_page_id,
      // ...(this.data.company_id ? {company_id: this.data.company_id} : {})
    }
  }
    else orderData = {
      data: data ? data : this.data,
      current_page: this.current_page_id,
      // ...(this.data.company_id ? {company_id: this.data.company_id} : {})
    }
    
    return this.apiUpdate({
      company: companyData ? companyData : undefined,
      order: {...orderData},
    }).catch((e) => console.error(e));
  }
}
